<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Siswa
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group label="NISN" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="dataStudent.nisn" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="NISN" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nama" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="dataStudent.name" type="text" :state="errors.length > 0 ? false : null"
                    placeholder="Nama" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Tempat Lahir" label-for="birth_place">
                <validation-provider #default="{ errors }" name="birth_place" rules="required">
                  <b-form-input id="birth_place" v-model="dataStudent.birth_place" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Tempat Lahir" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Tanggal Lahir" label-for="birth_date">
                <validation-provider #default="{ errors }" name="birth_date" rules="required">
                  <b-form-input id="birth_date" v-model="dataStudent.birth_date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="Tanggal Lahir" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="Jenis Kelamin" rules="required">
                <b-form-group label="Jenis Kelamin" label-for="gender" :state="errors.length > 0 ? false : null">
                  <v-select id="gender" v-model="dataStudent.gender" :reduce="(status) => status.value"
                    placeholder="Pilih Jenis Kelamin" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataGender" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Agama" rules="required">
                <b-form-group label="Agama" label-for="religion" :state="errors.length > 0 ? false : null">
                  <v-select id="religion" v-model="dataStudent.religion" :reduce="(religion) => religion.value"
                    placeholder="Pilih Agama" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataReligion"
                    label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Kebangsaan" label-for="nationality">
                <validation-provider #default="{ errors }" name="nationality" rules="required">
                  <b-form-input id="nationality" v-model="dataStudent.nationality" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Kebangsaan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Anak ke (dikeluarga)" label-for="child_number">
                <validation-provider #default="{ errors }" name="Anak ke (dikeluarga)" rules="required">
                  <b-form-input id="child_number" v-model="dataStudent.child_number" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Jumlah Sudara Kandung" label-for="number_of_sibling">
                <validation-provider #default="{ errors }" name="Jumlah Sudara Kandung" rules="required">
                  <b-form-input id="number_of_sibling" v-model="dataStudent.number_of_sibling" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="3" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Bahasa Sehari-hari" label-for="every_day_language">
                <validation-provider #default="{ errors }" name="Bahasa Sehari-hari" rules="required">
                  <b-form-input id="every_day_language" v-model="dataStudent.every_day_language"
                    :state="errors.length > 0 ? false : null" placeholder="Bahasa Indonesia" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider #default="{ errors }" name="Provinsi" rules="required">
                <b-form-group label="Provinsi" label-for="province" :state="errors.length > 0 ? false : null">
                  <v-select id="province" v-model="locations.province" placeholder="Pilih Provinsi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataProvinces" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Kabupaten/Kota" rules="required">
                <b-form-group label="Kabupaten/Kota" label-for="city" :state="errors.length > 0 ? false : null">
                  <v-select id="city" v-model="locations.city" placeholder="Pilih Kabupaten/Kota"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterCities" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Kecamatan" rules="required">
                <b-form-group label="Kecamatan" label-for="distric" :state="errors.length > 0 ? false : null">
                  <v-select id="distric" v-model="locations.distric" placeholder="Pilih Kecamatan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterDistrics" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Desa" rules="required">
                <b-form-group label="Desa" label-for="subdistrict" :state="errors.length > 0 ? false : null">
                  <v-select id="subdistrict" v-model="locations.subdistrict" placeholder="Pilih Desa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterSubDistrics" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Alamat" label-for="address">
                <validation-provider #default="{ errors }" name="address" rules="required">
                  <b-form-input id="address" v-model="dataStudent.address" :state="errors.length > 0 ? false : null"
                    placeholder="Jl. Kaliurang Km. 7 No.B5" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="RW" label-for="rw">
                <validation-provider #default="{ errors }" name="rw" rules="required">
                  <b-form-input id="rw" v-model="dataStudent.rw" :state="errors.length > 0 ? false : null"
                    placeholder="11" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="RT" label-for="rt">
                <validation-provider #default="{ errors }" name="rt" rules="required">
                  <b-form-input id="rt" v-model="dataStudent.rt" :state="errors.length > 0 ? false : null"
                    placeholder="21" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">




              <b-form-group label="Jarak Ke Sekolah" label-for="distance">
                <validation-provider #default="{ errors }" name="Jarak Ke Sekolah" rules="required">
                  <b-form-input id="distance" v-model="dataStudent.distance" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="14" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="blood_type" rules="required">
                <b-form-group label="Golongan Darah" label-for="blood_type" :state="errors.length > 0 ? false : null">
                  <v-select id="blood_type" v-model="dataStudent.blood_type" :reduce="(blood_type) => blood_type.id"
                    placeholder="Pilih Golongan Darah" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listBloods" label="blood_type_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Riwayat Penyakit" label-for="illness_history">
                <validation-provider #default="{ errors }" name="Riwayat Penyakit" rules="required">
                  <b-form-input id="illness_history" v-model="dataStudent.illness_history"
                    :state="errors.length > 0 ? false : null" placeholder="Asma" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="Kemampuan Membaca Al-Qur'an" rules="required">
                <b-form-group label="Kemampuan Membaca Al-Qur'an" label-for="reading_ability_quran"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="reading_ability_quran" v-model="dataStudent.reading_ability_quran"
                    :reduce="(read) => read.value" placeholder="Pilih Kemampuan Membaca Al-Qur'an"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataReadingQuran" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="No. Handphone" label-for="phone">
                <validation-provider #default="{ errors }" name="No. Handphone" rules="required">
                  <b-form-input id="phone" v-model="dataStudent.phone" :state="errors.length > 0 ? false : null"
                    placeholder="08xxxxxxxxx" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Email" label-for="email">
                <validation-provider #default="{ errors }" name="Email" rules="required">
                  <b-form-input id="email" v-model="dataStudent.phone_2" :state="errors.length > 0 ? false : null"
                    placeholder="admin@admin.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nama Ayah" label-for="father_name">
                <validation-provider #default="{ errors }" name="father_name" rules="required">
                  <b-form-input id="father_name" v-model="dataStudent.father_name"
                    :state="errors.length > 0 ? false : null" placeholder="Andi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nama Ibu" label-for="mother_name">
                <validation-provider #default="{ errors }" name="mother_name" rules="required">
                  <b-form-input id="mother_name" v-model="dataStudent.mother_name"
                    :state="errors.length > 0 ? false : null" placeholder="Susi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Anak ke (1 Lembaga)" label-for="child_number_institution">
                <validation-provider #default="{ errors }" name="Anak ke (1 Lembaga)" rules="required">
                  <b-form-input id="child_number_institution" v-model="dataStudent.child_number_institution" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="father_education_level_id" rules="required">
                <b-form-group label="Pendidikan Terakhir Ayah" label-for="father_education_level_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="father_education_level_id" v-model="dataStudent.father_education_level_id"
                    :reduce="(father_education_level_id) => father_education_level_id.id"
                    placeholder="Pendidikan Terakhir Ayah" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listEducations" label="level_education_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="mother_education_level_id" rules="required">
                <b-form-group label="Pendidikan Terakhir Ibu" label-for="mother_education_level_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="mother_education_level_id" v-model="dataStudent.mother_education_level_id"
                    :reduce="(mother_education_level_id) => mother_education_level_id.id"
                    placeholder="Pendidikan Terakhir Ibu" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listEducations" label="level_education_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Mutation" rules="required">
                <b-form-group label="Mutation" label-for="mutation" :state="errors.length > 0 ? false : null">
                  <v-select id="mutation" v-model="dataStudent.mutation" :reduce="(mutation) => mutation.value"
                    placeholder="Pilih Mutation" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataMutations" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="accepted_in_class" rules="required">
                <b-form-group label="DiTerima di Kelas" label-for="accepted_in_class"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="accepted_in_class" v-model="dataStudent.accepted_in_class"
                    :reduce="(accepted_in_class) => accepted_in_class.id" placeholder="DiTerima di Kelas"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass" label="description" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataStudent: {
        nisn: '',
        name: '',
        birth_place: '',
        birth_date: '',
        gender: '',
        religion: '',
        nationality: '',
        child_number: '',
        number_of_sibling: '',
        relationship_in_family: '',
        every_day_language: '',
        address: '',
        distance: '',
        child_number_institution: '',
        reading_ability_quran: '',
        blood_type: '',
        illness_history: '',
        father_name: '',
        mother_name: '',
        father_birth_place: '',
        mother_birth_place: '',
        father_birth_date: '',
        mother_birth_date: '',
        father_religion: '',
        mother_religion: '',
        father_nationality: '',
        mother_nationality: '',
        father_education_level_id: '',
        mother_education_level_id: '',
        father_education_major: '',
        mother_education_major: '',
        father_salary: '',
        mother_salary: '',
        father_profession: '',
        mother_profession: '',
        father_rw: '',
        mother_rw: '',
        father_rt: '',
        mother_rt: '',
        father_address: '',
        mother_address: '',
        father_parental_status: '',
        mother_parental_status: '',
        father_handphone: '',
        mother_handphone: '',
        father_email: '',
        mother_email: '',
        father_nik: '',
        mother_nik: '',
        mutation: '',
        school_origin: '',
        school_address: '',
        year_of_entry: '',
        graduation_year: '',
        skhun_no: '',
        ijazah_no: '',
        reason_to_move: '',
        accepted_in_class: '',
        effective_date_received: '',
        fc_kk: null,
        fc_akta: null,
        fc_ijazah: null,
        color_photo: null,
        photo: null,
        other_document: null,
      },
      locations: {
        province: '',
        city: '',
        distric: '',
        subdistric: '',
      },
      dataGender: [
        { value: 'M', text: 'Laki-laki' },
        { value: 'F', text: 'Perempuan' },
      ],
      dataReligion: [
        { value: 1, text: 'Islam' },
        { value: 2, text: 'Protestan' },
        { value: 3, text: 'Katolik' },
        { value: 4, text: 'Hindu' },
        { value: 5, text: 'Buddha' },
        { value: 6, text: 'Khonghucu' },
      ],
      dataReadingQuran: [
        { value: 'lancar', text: 'Lancar' },
        { value: 'belum_lancar', text: 'Belum Lancar' },
        { value: 'belum_bisa', text: 'Belum Bisa' },
      ],
      dataEducation: [{ value: 1, text: 'SD/MI' }],
      dataStudentStatus: [
        { value: '1', text: 'Ayah' },
        { value: '2', text: 'Ibu' },
        { value: '3', text: 'Wali' },
      ],
      dataStudentEducation: [
        { value: '1', text: 'SD' },
        { value: '2', text: 'SMP' },
        { value: '3', text: 'SMA' },
        { value: '4', text: 'S1' },
        { value: '5', text: 'S2' },
        { value: '6', text: 'S3' },
      ],
      dataMutations: [
        { value: 'Y', text: 'Ya' },
        { value: 'N', text: 'Tidak' },
      ],
      dataMaritalStatus: [
        { value: 1, text: 'Menikah' },
        { value: 2, text: 'Belum Menikah' },
      ],
      dataIsActive: [
        { value: 1, text: 'Aktif' },
        { value: 0, text: 'Tidak Aktif' },
      ],
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
      listEthnic: [],
      listBloods: [],
      listEducations: [],
      listClass: []
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  created() {
    this.getethnic(),
      this.getbloodtypes(),
      this.geteducation(),
      this.getclass()
  },
  methods: {
    async getclass() {
      try {
        const response = await this.$http.get('/academicclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async geteducation() {
      try {
        const response = await this.$http.get('/leveleducations')
        this.listEducations = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getbloodtypes() {
      try {
        const response = await this.$http.get('/bloodtypes')
        this.listBloods = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getethnic() {
      try {
        const response = await this.$http.get('/ethnics')
        this.listEthnic = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormStudent = new FormData()
        Object.keys(this.dataStudent).forEach(key => {
          dataFormStudent.append(key, this.dataStudent[key])
        })
        dataFormStudent.append('province', this.locations.province.name)
        dataFormStudent.append('city', this.locations.city.name)
        dataFormStudent.append('district', this.locations.distric.name)
        dataFormStudent.append('subdistrict', this.locations.subdistric.name)
        await this.$http.post('/students', dataFormStudent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-siswa' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
